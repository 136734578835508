
import React, { Component } from 'react';
import BaseHead from './Head';
import { Navbar } from 'react-bootstrap';
import styled from 'styled-components';

import Menu from 'components/Menu';
import Link from 'next/link';
import { FavoriteToolbar } from 'components/Favorite';
import SearchToolbar from 'components/Search/SearchToolbar';


const Container = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  padding: 20px;
  grid-gap: 20px;
`;

const StyledNavbarBrand = styled(Navbar.Brand)` 
  a {
    font-size: 14px;
    display: grid;
    grid-template-columns: 30px 100px;
    grid-gap: 10px;
    line-height: 16px;
    color: #157EFB;
    
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledNavbar = styled(Navbar)`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-gap: 20px;
`;


export default class Layout extends Component {

  static Head = BaseHead;

  render() {
    return (
      <div>
        <StyledNavbar
          bg="light"
        >
          <StyledNavbarBrand>
            <Link href={'/'}>
              <a>
                <img
                  src="/assets/logo.svg"
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="Общероссийские классификаторы"
                />
                Общероссийские<br/>классификаторы
              </a>
            </Link>
          </StyledNavbarBrand>
          <div>
            <Link href={'/article'}>
              <a>Статьи</a>
            </Link>
          </div>
          <div>
            <SearchToolbar />
          </div>
          <div>
            <FavoriteToolbar />
          </div>
        </StyledNavbar>
        <Container>
          <div>
            <Menu />
          </div>
          <div>
            {this.props.children}
          </div>
        </Container>
      </div>
    )
  }
}
