
import React, { Component } from 'react'
import Link from 'next/link';
import styled from 'styled-components';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { AppContext} from 'AppRovider';

const StyledMenuItem = styled(ListGroupItem)`
  a {
    font-size: 16px;
  }
  
  .designation {
    font-weight: bold;
    font-size: 12px;
  }
  
  .full-name {
    font-size: 12px;
  }
`;


class MenuItem extends Component {
  render() {
    const { item } = this.props;

    return (
      <StyledMenuItem>
        <Link
          href={'/[slug]'}
          as={`/${item.slug}`}
        >
          <a>{item.name}</a>
        </Link>
        {item.designation && (
          <div className={'designation'}>{item.designation}</div>
        )}
        <div className={'full-name'}>{item.full_name}</div>
      </StyledMenuItem>
    )
  }
}


export default class Menu extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {context => {
          const { classifiers } = context;
          return (
            <ListGroup>
              {classifiers.map(item =>
                <MenuItem item={item} key={item.id} />
              )}
            </ListGroup>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
