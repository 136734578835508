
import React from "react";
import Highlighter from "react-highlight-words";
import styled from "styled-components";
import {Badge} from "react-bootstrap";


const StyledBadge = styled(Badge)`
  font-weight: normal;
`;

const StyledExtraOptionResult = styled(Badge)`
  font-size: 12px;
  font-weight: normal;
  
  span {
    margin-right: 5px;
  }
`;

function DefaultHighlighter({words, text}) {
  return (
    <Highlighter
      highlightClassName={'rbt-highlight-text'}
      searchWords={words}
      autoEscape={true}
      textToHighlight={text}
    />
  )
}

function ExtraOptionResult({option, words}) {
  if (option.classifier__slug === 'organization' || option.classifier__slug === 'medical-organization') {
    return (
      <StyledExtraOptionResult>
        <span>ИНН: <DefaultHighlighter words={words} text={option.data.inn} /></span>
        {option.data.ogrn && <span>ОГРН: <DefaultHighlighter words={words} text={option.data.ogrn} /></span>}
      </StyledExtraOptionResult>
    )
  }
  return <></>
}


export default function SearchItemResult({option, props, idx}) {
  const words = props.text.split(' ');
  return (
    <>
      <div>
        <StyledBadge variant="secondary">{option.classifier__name}</StyledBadge>{' '}
        <DefaultHighlighter words={words} text={`${option.code ? `${option.code} - ` : ''}${option.name}`} />
      </div>
      <ExtraOptionResult option={option} words={words} />
    </>
  )
}
