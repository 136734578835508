
import React, { Component } from 'react';
import { withRouter } from 'next/router';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import Highlighter from 'react-highlight-words';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

import { clientApi } from 'Config';
import { AppContext } from 'AppRovider';
import SearchItemResult from './SearchItemResult';

const AsyncTypeahead = withAsync(Typeahead);


class SearchToolbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      options: [],
      loading: false,
      selected: undefined
    };
  }

  onChange = (option) => {
    this.setState({selected: option});
    if (option.length > 0) {
      this.props.router.push('/[...slug]', option[0].url);
    }
  };

  render() {
    const { loading, options } = this.state;
    return (
      <AppContext.Consumer>
        {context => {
          const classifier = context.getCurrentClassifier();
          let placeholder = 'Поиск по классификаторам';
          if (classifier) {
            placeholder = `Поиск по классификатору ${classifier.name}`
          }

          return (
            <div>
              <AsyncTypeahead
                id={"search"}
                isLoading={loading}
                useCache={false}
                labelKey={option => `${option.code ? `${option.code} - ` : ''}${option.name}`}
                searchText={'Поиск'}
                placeholder={placeholder}
                emptyLabel={'Ничего не найдено'}
                options={options}
                filterBy={() => true}
                clearButton={true}
                onSearch={(query) => {
                  this.setState({loading: true});
                  clientApi.get('client/search/', {params: {q: query, classifier: classifier ? classifier.id : null}}).then(res => {
                    this.setState({
                      loading: false,
                      options: res.data,
                    })
                  })
                }}
                onChange={(selected) => this.onChange(selected)}
                renderMenuItemChildren={(option, props, idx) => {
                  return (
                    <SearchItemResult option={option} props={props} idx={idx} />
                  )
                }}
              />
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default withRouter(SearchToolbar);
