
import React from 'react';
import Head from 'next/head';
import { AppContext } from 'AppRovider';


export default function BaseHead({ title, description, keywords, canonical }) {
  return (
    <AppContext.Consumer>
      {context => {
        return (
          <Head>
            {title && <title>{title}</title>}
            {description && (
              <meta name="Description" content={description}/>
            )}
            {keywords && (
              <meta name="keywords" content={keywords}/>
            )}

            <link rel="icon" href="/favicon.ico"/>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            <meta name="Copyright" content="Справочники"/>
            <meta name="Author" content="https://classifier.su/"/>
            {canonical && (
              <link rel="canonical" href={`https://classifier.su${canonical}`}/>
            )}
          </Head>
        )
      }}
    </AppContext.Consumer>
  )
}
